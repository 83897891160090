"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.get = exports.isDeepEqual = exports.assocPath = void 0;
var isObject = function (object) {
    return object != null && typeof object === "object";
};
function assocPath(path, value, obj) {
    if (path.length === 0)
        return value;
    var key = path[0], rest = path.slice(1);
    var newObj = Array.isArray(obj) ? __spreadArray([], obj, true) : __assign({}, obj);
    newObj[key] = assocPath(rest, value, newObj[key] || {});
    return newObj;
}
exports.assocPath = assocPath;
function isDeepEqual(object1, object2) {
    var objKeys1 = Object.keys(object1);
    var objKeys2 = Object.keys(object2);
    if (objKeys1.length !== objKeys2.length)
        return false;
    for (var _i = 0, objKeys1_1 = objKeys1; _i < objKeys1_1.length; _i++) {
        var key = objKeys1_1[_i];
        var value1 = object1[key];
        var value2 = object2[key];
        var isObjects = isObject(value1) && isObject(value2);
        if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false;
        }
    }
    return true;
}
exports.isDeepEqual = isDeepEqual;
function get(path, obj, defaultValue) {
    if (defaultValue === void 0) { defaultValue = undefined; }
    var travel = function (regexp) {
        return String.prototype.split
            .call(path, regexp)
            .filter(Boolean)
            .reduce(function (res, key) { return (res !== null && res !== undefined ? res[key] : res); }, obj);
    };
    var result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
}
exports.get = get;
